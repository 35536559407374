<template>
  <router-view/>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}
@import url("@/style/reset.scss");

.search-group {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    margin: 10px 0;

    .search-item {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin: 0 12px;
        .search-label {
            margin-right: 10px;
            font-weight: bold;
        }
    }
}

.xt-table-pagination {
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.xt-table-op-btns {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

</style>
